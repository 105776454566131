/* Global Styles */
* {
  box-sizing: border-box;
}

:root {
  --seekare-bg-color: #f7fafc;
}

body {
  margin: 0;
  font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #2f2f2f; */
  background-color: transparent;
  overflow: hidden;
}

p {
  font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

a {
  text-decoration: none;
}

#root {
  background-color: var(--seekare-bg-color);
  border-radius: 10px;
  height: 100vh;
  overflow: hidden;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bbb7b7;
  border-radius: 16px;
}

.hidden-track::-webkit-scrollbar-track {
  background-color: transparent;
}

.hidden-track::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.hidden-track::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 16px;
}